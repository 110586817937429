import {
  CORP_HOMEPAGE_SUBTITLE,
  CORP_HOMEPAGE_TITLE,
  CORP_HOMEPAGE_TITLE_DBC_EXPERIMENT,
  isCorpTenant,
} from "@capone/common";
import { useExperimentsById } from "@capone/experiments";
import { Box, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import dayjs from "dayjs";
import { LandingBenefits, useDeviceTypes, usePrevious } from "halifax";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import {
  APPLIED_PREFERENCES,
  AUTOCOMPLETE_LOCATION_SELECTED,
  AppliedPreferencesProperties,
  AutocompleteLocationSelectedProperties,
  CALENDAR_DATES_SELECTED,
  CalendarDatesSelectedProperties,
  CallState,
  HotelEntryTypeEnum,
  LAUNCHED_MOBILE_LOCATION_SCREEN,
  LaunchedMobileLocationScreenProperties,
  RecentHotelSearch,
  Tenant,
} from "redmond";
import { ClientContext } from "../../App";
import { config } from "../../api/config";
import { trackEvent } from "../../api/v0/analytics/trackEvent";
import {
  AVAILABLE,
  CASH_VALUEPROP_A,
  CASH_VALUEPROP_B,
  CASH_VALUEPROP_C,
  CASH_VALUEPROP_EXPERIMENT,
  CASH_VALUEPROP_VARIANTS,
  CONTROL,
  CUSTOMER_PROFILE_EXPERIMENT,
  CUSTOMER_PROFILE_VARIANTS,
  DEFAULT_ON,
  DESKTOP_RECENTLY_VIEWED_HOTELS,
  DESKTOP_RECENTLY_VIEWED_HOTELS_VARIANTS,
  HOTEL_COLOR_CALENDAR,
  HOTEL_COLOR_CALENDAR_VARIANTS,
  HOTEL_COLOR_CALENDAR_WITH_PRICING,
  MOBILE_HOMESCREEN_REDESIGN_EXPERIMENT,
  MOBILE_HOMESCREEN_REDESIGN_V2,
  MOBILE_HOMESCREEN_REDESIGN_V3,
  MOBILE_HOMESCREEN_REDESIGN_VARIANTS,
  MOBILE_RECENTLY_VIEWED_HOTELS,
  MOBILE_RECENTLY_VIEWED_HOTELS_VARIANTS,
  STAYS_HOMEPAGE,
  addTrackingProperties,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  useExperiments,
} from "../../context/experiments";
import {
  PORTAL_TITLE,
  SEARCH_HOTEL_TITLE,
  SEARCH_STAYS_TITLE,
} from "../../lang/textConstants";
import { PATH_AVAILABILITY, PATH_STAYS_AVAILABILITY } from "../../utils/paths";
import { transformToStringifiedAvailabilityQuery } from "../shop/utils/queryStringHelpers";
import { HotelSearchControl } from "./components/HotelSearchControl";
import { MobileHotelSearchControl } from "./components/MobileHotelSearchControl";
import { MobileHotelSearchControlV2 } from "./components/MobileHotelSearchControlV2";
import { MobileHotelSearchControlV3 } from "./components/MobileHotelSearchControlV3";
import { HotelsSearchConnectorProps } from "./container";
import "./styles.scss";
import * as textConstants from "./textConstants";

export const HotelsSearch = (props: HotelsSearchConnectorProps) => {
  const {
    fetchRewardsAccounts,
    rewardsAccounts,
    largestValueAccount,
    fetchRewardsAccountsCallState,
    fetchUserHotelPreferences,
    shouldApplyUserHotelPreferences,
    setApplyUserHotelPreferences,
    userHasSetHotelPreferences,
    userHotelPreferencesCallState,
    resetHotelAvailabilityCallState,
    listPaymentMethods,
  } = props;
  const { matchesMobile } = useDeviceTypes();
  const history = useHistory();
  const [recentSearches, setRecentSearches] = useState<RecentHotelSearch[]>([]);

  const { sessionInfo } = useContext(ClientContext);

  const expState = useExperiments();

  const cashValuePropVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    CASH_VALUEPROP_EXPERIMENT,
    CASH_VALUEPROP_VARIANTS
  );

  const mobileHomeScreenVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    MOBILE_HOMESCREEN_REDESIGN_EXPERIMENT,
    MOBILE_HOMESCREEN_REDESIGN_VARIANTS
  );

  const isRecentlyViewedHotelsDesktopExperiment =
    getExperimentVariantCustomVariants(
      expState.experiments,
      DESKTOP_RECENTLY_VIEWED_HOTELS,
      DESKTOP_RECENTLY_VIEWED_HOTELS_VARIANTS
    ) !== CONTROL;

  const isRecentlyViewedHotelsMobileExperiment =
    getExperimentVariantCustomVariants(
      expState.experiments,
      MOBILE_RECENTLY_VIEWED_HOTELS,
      MOBILE_RECENTLY_VIEWED_HOTELS_VARIANTS
    ) !== CONTROL;

  const colorCalendarExperimentVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    HOTEL_COLOR_CALENDAR,
    HOTEL_COLOR_CALENDAR_VARIANTS
  );

  const customerProfileExperimentVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    CUSTOMER_PROFILE_EXPERIMENT,
    CUSTOMER_PROFILE_VARIANTS
  );

  const isCustomerProfileExperiment =
    customerProfileExperimentVariant !== CONTROL;

  const staysHomepageEnabled =
    getExperimentVariant(expState.experiments, STAYS_HOMEPAGE) === AVAILABLE;

  const hasEarnToDisplay = useMemo(() => {
    return !!largestValueAccount && !!largestValueAccount.earn.hotelsMultiplier;
  }, [largestValueAccount]);

  const showDBCCustomHeader =
    useExperimentsById("corp-custom-header-logo-dbc")?.variant === "available";

  const getTitleToDisplay = (tenant: Tenant) => {
    if (isCorpTenant(tenant)) {
      return showDBCCustomHeader
        ? CORP_HOMEPAGE_TITLE_DBC_EXPERIMENT
        : CORP_HOMEPAGE_TITLE;
    } else if (
      fetchRewardsAccountsCallState === CallState.Success &&
      hasEarnToDisplay
    ) {
      return textConstants.EARN_ENHANCEMENT_TITLE(
        largestValueAccount.earn.hotelsMultiplier,
        staysHomepageEnabled ? largestValueAccount.earn.homesMultiplier : "",
        largestValueAccount.productDisplayName,
        largestValueAccount.rewardsBalance.currencyDescription ??
          largestValueAccount.rewardsBalance.currency
      );
    } else if (
      fetchRewardsAccountsCallState === CallState.Success ||
      fetchRewardsAccountsCallState === CallState.Failed
    ) {
      if (
        largestValueAccount?.productDisplayName
          .toLowerCase()
          .includes("paradise")
      ) {
        return (
          <span className="font-regular">{textConstants.PARADISE_TITLE}</span>
        );
      } else {
        return (
          <span className="font-regular">
            {staysHomepageEnabled
              ? textConstants.STAYS_TITLE
              : textConstants.TITLE}
          </span>
        );
      }
    } else {
      return <Skeleton className="title-loading" />;
    }
  };

  const getSubtitleToDisplay = (tenant: Tenant) => {
    if (isCorpTenant(tenant)) {
      return CORP_HOMEPAGE_SUBTITLE;
    } else if (
      fetchRewardsAccountsCallState === CallState.Success ||
      fetchRewardsAccountsCallState === CallState.Failed
    ) {
      return textConstants.SUBTITLE;
    } else {
      return <Skeleton className="subtitle-loading" />;
    }
  };

  const onRecentSearchClick = useCallback(
    (search: RecentHotelSearch) => {
      const path = staysHomepageEnabled
        ? PATH_STAYS_AVAILABILITY
        : PATH_AVAILABILITY;

      history.push(
        `${path}${transformToStringifiedAvailabilityQuery(
          search.location,
          search.checkInDate,
          search.checkOutDate,
          search.numAdults,
          search.childrenAges.length
            ? search.childrenAges
            : Array(search.numChildren).fill(17),
          search.numRooms,
          search.numPets,
          HotelEntryTypeEnum.RECENTLY_SEARCH_AUTOCOMPLETE
        )}`
      );
    },
    [history]
  );

  useEffect(() => {
    const documentTitle = staysHomepageEnabled
      ? SEARCH_STAYS_TITLE
      : SEARCH_HOTEL_TITLE;
    document.title = documentTitle;

    return () => {
      document.title = PORTAL_TITLE;
    };
  }, [staysHomepageEnabled]);

  useEffect(() => {
    fetchRewardsAccounts(false, sessionInfo);
    listPaymentMethods();

    setTimeout(() => window.scrollTo(0, 0), 0);

    resetHotelAvailabilityCallState();
  }, []);

  useEffect(() => {
    if (isCustomerProfileExperiment) {
      fetchUserHotelPreferences();
    }
  }, [isCustomerProfileExperiment]);

  useEffect(() => {
    if (
      customerProfileExperimentVariant === DEFAULT_ON &&
      userHotelPreferencesCallState === CallState.Success &&
      userHasSetHotelPreferences
    ) {
      setApplyUserHotelPreferences(true);
    }
  }, [
    customerProfileExperimentVariant,
    userHasSetHotelPreferences,
    userHotelPreferencesCallState,
  ]);

  useEffect(() => {
    if (matchesMobile) {
      const properties: LaunchedMobileLocationScreenProperties = {
        funnel: "hotels",
        url: window.location.pathname,
      };
      trackEvent({ eventName: LAUNCHED_MOBILE_LOCATION_SCREEN, properties });
    }
  }, [matchesMobile]);

  const handleLocationSelected = useCallback((value: any) => {
    if (value?.label) {
      const properties: AutocompleteLocationSelectedProperties = {
        funnel: "hotels",
        url: window.location.pathname,
        autocomplete_value: value.label,
      };
      trackEvent({ eventName: AUTOCOMPLETE_LOCATION_SELECTED, properties });
    }
  }, []);

  const handleDatesSelected = useCallback(
    (from: Date | null, until: Date | null) => {
      if (from && until) {
        const properties: CalendarDatesSelectedProperties = {
          funnel: "hotels",
          url: window.location.pathname,
          start_date: dayjs(from).format("YYYY-MM-DD"),
          end_date: dayjs(until).format("YYYY-MM-DD"),
        };
        trackEvent({ eventName: CALENDAR_DATES_SELECTED, properties });
      }
    },
    []
  );

  useEffect(() => {
    const getRecentSearches = () => {
      try {
        const localStorageItem = localStorage.getItem(
          "recently_searched_hotels"
        );

        if (localStorageItem) {
          const parsedSearches: RecentHotelSearch[] =
            JSON.parse(localStorageItem);
          const sortedSearches = parsedSearches
            .filter((search) => !!search.location)
            .sort(
              (a, b) =>
                new Date(b.searchDate).getTime() -
                new Date(a.searchDate).getTime()
            );
          setRecentSearches(sortedSearches);
        }
      } catch (e) {
        console.error(`Failed to get recent searches from local storage: ${e}`);
      }
    };

    getRecentSearches();

    addEventListener("update_recently_searched_hotels", getRecentSearches);

    return () =>
      removeEventListener("update_recently_searched_hotels", getRecentSearches);
  }, []);

  const prevShouldApplyUserHotelPreferences = usePrevious(
    shouldApplyUserHotelPreferences
  );

  useEffect(() => {
    if (
      prevShouldApplyUserHotelPreferences === false &&
      shouldApplyUserHotelPreferences === true
    ) {
      const properties: AppliedPreferencesProperties = {
        preferences_type: "hotels",
        location: "search",
      };

      trackEvent({
        eventName: APPLIED_PREFERENCES,
        properties: addTrackingProperties(
          expState.trackingProperties,
          properties
        ),
      });
    }
  }, [shouldApplyUserHotelPreferences, prevShouldApplyUserHotelPreferences]);

  const hasOnlyCashCards = useMemo(
    () =>
      rewardsAccounts?.length > 0 &&
      rewardsAccounts.every(
        (account) => account.rewardsBalance.currency.toLowerCase() === "cash"
      ),
    [rewardsAccounts]
  );

  const benefitsVariant = useMemo(() => {
    switch (cashValuePropVariant) {
      case CONTROL:
      default:
        return [];
      case CASH_VALUEPROP_A:
        return textConstants.CASH_CARD_BENEFITS.VARIANT_A;
      case CASH_VALUEPROP_B:
        return textConstants.CASH_CARD_BENEFITS.VARIANT_B;
      case CASH_VALUEPROP_C:
        return textConstants.CASH_CARD_BENEFITS.VARIANT_C;
    }
  }, [cashValuePropVariant]);

  return (
    <>
      {matchesMobile ? (
        <Box
          className={clsx("mobile-hotel-search-root", {
            "redesign-v2":
              mobileHomeScreenVariant === MOBILE_HOMESCREEN_REDESIGN_V2,
            "redesign-v3":
              mobileHomeScreenVariant === MOBILE_HOMESCREEN_REDESIGN_V3,
          })}
        >
          <Box className="hotel-search-container-mobile">
            {mobileHomeScreenVariant === MOBILE_HOMESCREEN_REDESIGN_V2 ? (
              <>
                <MobileHotelSearchControlV2
                  recentSearches={
                    isRecentlyViewedHotelsMobileExperiment
                      ? recentSearches
                      : undefined
                  }
                  onRecentSearchClick={onRecentSearchClick}
                  onSelectLocation={handleLocationSelected}
                  onSelectDates={handleDatesSelected}
                />
                <Box className="img-container"></Box>
              </>
            ) : mobileHomeScreenVariant === MOBILE_HOMESCREEN_REDESIGN_V3 ? (
              <MobileHotelSearchControlV3
                recentSearches={
                  isRecentlyViewedHotelsMobileExperiment
                    ? recentSearches
                    : undefined
                }
                onRecentSearchClick={onRecentSearchClick}
                onSelectLocation={handleLocationSelected}
                onSelectDates={handleDatesSelected}
                showCalendarPricingColors={
                  colorCalendarExperimentVariant !== CONTROL
                }
                showCalendarPricing={
                  colorCalendarExperimentVariant ===
                  HOTEL_COLOR_CALENDAR_WITH_PRICING
                }
                isCustomerProfileExperiment={isCustomerProfileExperiment}
              />
            ) : (
              <MobileHotelSearchControl
                recentSearches={
                  isRecentlyViewedHotelsMobileExperiment
                    ? recentSearches
                    : undefined
                }
                onRecentSearchClick={onRecentSearchClick}
                onSelectLocation={handleLocationSelected}
                onSelectDates={handleDatesSelected}
              />
            )}
          </Box>
        </Box>
      ) : (
        <Box className={clsx("hotel-search-root", "multiroom")}>
          <Box
            className={clsx("hotel-search-container-desktop", config.TENANT)}
          >
            <Box className={clsx("hotel-search-titles-and-fields-wrapper")}>
              <Typography variant="h1" className="search-title">
                {getTitleToDisplay(config.TENANT)}
              </Typography>
              <Typography variant="h5" className="search-subtitle">
                {getSubtitleToDisplay(config.TENANT)}
              </Typography>
              <HotelSearchControl
                saveDatesOnClose
                showTotalTravelers
                recentSearches={
                  isRecentlyViewedHotelsDesktopExperiment
                    ? recentSearches
                    : undefined
                }
                onRecentSearchClick={onRecentSearchClick}
                showPreferencesBanner
              />
            </Box>
          </Box>
          {cashValuePropVariant !== CONTROL && hasOnlyCashCards && (
            <LandingBenefits
              title={textConstants.CASH_CARD_BENEFITS_TITLE(
                rewardsAccounts?.[0]?.productDisplayName
              )}
              benefits={benefitsVariant}
            />
          )}
        </Box>
      )}
    </>
  );
};
