import { isCaponeTenant, isCorpTenant } from "@capone/common";
import { Box, Typography } from "@material-ui/core";
import {
  B2BLoadingPopup,
  CorpHotelSearchLoadingImage,
  Header,
  HotelSearchLoadingImage,
  HotelSplitMapView,
  mapTopAmenitiesToAmenities,
  useDeviceTypes,
} from "halifax";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { RouterProps } from "react-router";
import {
  APPLIED_PREFERENCES,
  AppliedPreferencesProperties,
  IIdLodgings,
} from "redmond";

import clsx from "clsx";
import dayjs from "dayjs";

import { ClientContext } from "../../App";
import { PATH_HOME, PATH_HOME_STAYS } from "../../utils/paths";
import {
  PORTAL_TITLE,
  SELECT_HOTEL_TITLE,
  SELECT_STAYS_TITLE,
} from "../../lang/textConstants";
import {
  useExperiments,
  getExperimentVariant,
  AVAILABLE,
  TRAVEL_WALLET_CREDITS_EXPERIMENT,
  TRAVEL_CREDIT_HISTORY_EXPERIMENT,
  CONTROL,
  CUSTOMER_PROFILE_EXPERIMENT,
  addTrackingProperties,
  getExperimentVariantCustomVariants,
  CUSTOMER_PROFILE_VARIANTS,
  STAYS_SEARCH,
} from "../../context/experiments";
import { config } from "../../api/config";
import { trackEvent } from "../../api/v0/analytics/trackEvent";
import { usePrevious } from "../../hooks/usePrevious";
import { PriceDropProtectionPopup } from "../ancillary/components/addOnComponents/PriceDropProtectionBanner/components";
import { RewardsAccountSelection } from "../rewards/components";
import { TravelWalletDrawer } from "../travel-wallet/components";
import {
  AvailabilityErrorModal,
  AvailabilityList,
  AvailabilityMap,
  MobileHotelAvailability,
} from "./components";
import { HotelAvailabilityConnectorProps } from "./container";
import { HotelAvailabilityCallState } from "./reducer/state";
import "./styles.scss";
import * as constants from "./textConstants";

export interface IHotelAvailability
  extends HotelAvailabilityConnectorProps,
    RouterProps {}

export const HotelAvailability = (props: IHotelAvailability) => {
  const {
    location,
    fromDate,
    untilDate,
    hotelAvailabilityCallState,
    fetchInitialHotelAvailability,
    history,
    fetchTravelWalletDetails,
    largestValueAccount,
    fetchTravelWalletCreditHistory,
    isSearchingMap,
    userPriceDropAmount,
    shouldApplyUserHotelPreferences,
    userHotelPreferences,
    setStarRatingsFilter,
    setAmenitiesFilter,
    setFreeCancelFilter,
    fetchUserHotelPreferences,
    listPaymentMethods,
  } = props;

  const { logo } = useContext(ClientContext);
  const { matchesMobile } = useDeviceTypes();
  const [locationName, setLocationName] = React.useState("");
  const [isSearchTermLodging, setIsSearchTermLodging] = React.useState(false);
  // If search term is location (ex: Toronto) vs if search term is point of interest (ex: Toronto Zoo)
  const [isSearchTermPoint, setIsSearchTermPoint] = React.useState(false);
  const [mobileMapOpen, setMobileMapOpen] = React.useState(false);

  const [currentUrl, setCurrentUrl] = React.useState(history.location.search);
  React.useEffect(() => {
    if (location) {
      const [locationName] = location?.label ? location.label.split(",") : [];
      setLocationName(locationName);
      const placeTypes = location
        ? (location.id as IIdLodgings).lodgingSelection.placeTypes
        : [];

      setIsSearchTermLodging(placeTypes.includes("lodging"));
      setIsSearchTermPoint(
        !placeTypes.includes("locality") &&
          !placeTypes.includes("political") &&
          !placeTypes.includes("country")
      );
    }
  }, [location]);

  const isMapSearch = useMemo(() => {
    return isSearchingMap;
  }, [isSearchingMap]);

  const expState = useExperiments();

  const travelWalletCreditsExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_CREDITS_EXPERIMENT
  );

  const staysSearchEnabled =
    getExperimentVariant(expState.experiments, STAYS_SEARCH) === AVAILABLE;

  const isTravelWalletCreditsExperiment = React.useMemo(
    () => travelWalletCreditsExperiment === AVAILABLE,
    [travelWalletCreditsExperiment]
  );

  const showEarnEnhancement =
    isCaponeTenant(config.TENANT) &&
    !!largestValueAccount &&
    !!largestValueAccount.earn.hotelsMultiplier;

  const travelCreditHistoryExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_CREDIT_HISTORY_EXPERIMENT
  );
  const isTravelCreditHistoryExperiment = useMemo(() => {
    return travelCreditHistoryExperiment === AVAILABLE;
  }, [travelCreditHistoryExperiment]);

  const [
    openPriceDropProtectionBannerModal,
    setOpenPriceDropProtectionBannerModal,
  ] = useState<boolean>(false);

  const customerProfileExperimentVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    CUSTOMER_PROFILE_EXPERIMENT,
    CUSTOMER_PROFILE_VARIANTS
  );

  const isCustomerProfileExperiment =
    customerProfileExperimentVariant !== CONTROL;

  useEffect(() => {
    const title = staysSearchEnabled ? SELECT_STAYS_TITLE : SELECT_HOTEL_TITLE;
    // TODO: Find out why window.scrollTo requires a setTimeout; it's needed so that the browser nav button works with scrollTo
    document.title = title;
    setTimeout(() => window.scrollTo(0, 0), 0);
    return () => {
      document.title = PORTAL_TITLE;
    };
  }, [staysSearchEnabled]);

  useEffect(() => {
    fetchTravelWalletDetails();
    listPaymentMethods();
  }, []);

  useEffect(() => {
    if (expState.experiments.length > 0) {
      let shouldIncludeHomes = false;
      if (staysSearchEnabled) {
        shouldIncludeHomes = true;
      }
      fetchInitialHotelAvailability({
        history,
        searchFromMap: false,
        searchHotelsNear: false,
        includeHomes: shouldIncludeHomes,
      });
    }
  }, [expState]);

  useEffect(() => {
    if (history.location.search !== currentUrl) {
      let shouldIncludeHomes = false;
      if (staysSearchEnabled) {
        shouldIncludeHomes = true;
      }
      fetchInitialHotelAvailability({
        history,
        searchFromMap: false,
        searchHotelsNear: false,
        includeHomes: shouldIncludeHomes,
      });
      setCurrentUrl(history.location.search);
    }
  }, [history.location.search]);

  useEffect(() => {
    if (isTravelCreditHistoryExperiment) {
      fetchTravelWalletCreditHistory();
    }
  }, [isTravelCreditHistoryExperiment]);

  useEffect(() => {
    if (isCustomerProfileExperiment) {
      fetchUserHotelPreferences();
    }
  }, [isCustomerProfileExperiment]);

  useEffect(() => {
    if (isCustomerProfileExperiment && shouldApplyUserHotelPreferences) {
      userHotelPreferences?.starRatings.length &&
        setStarRatingsFilter(userHotelPreferences.starRatings);
      userHotelPreferences?.amenities.length &&
        setAmenitiesFilter(
          mapTopAmenitiesToAmenities(userHotelPreferences.amenities)
        );
      userHotelPreferences?.freeCancellationOnly !== undefined &&
        setFreeCancelFilter(userHotelPreferences.freeCancellationOnly);
    }
  }, [
    isCustomerProfileExperiment,
    shouldApplyUserHotelPreferences,
    userHotelPreferences,
  ]);

  const prevShouldApplyUserHotelPreferences = usePrevious(
    shouldApplyUserHotelPreferences
  );

  useEffect(() => {
    if (
      prevShouldApplyUserHotelPreferences === false &&
      shouldApplyUserHotelPreferences === true
    ) {
      const properties: AppliedPreferencesProperties = {
        preferences_type: "hotels",
        location: "hotel_list",
      };

      trackEvent({
        eventName: APPLIED_PREFERENCES,
        properties: addTrackingProperties(
          expState.trackingProperties,
          properties
        ),
      });
    }
  }, [shouldApplyUserHotelPreferences, prevShouldApplyUserHotelPreferences]);

  const renderDesktopView = () => {
    return (
      <HotelSplitMapView
        className={clsx("hotel-availability-container", config.TENANT)}
        header={
          <Header
            className="rewards-components-section"
            left={
              <Box className={"rewards-account-section-left-content"}>
                <Box
                  className={"logo"}
                  onClick={() =>
                    history.push(
                      staysSearchEnabled ? PATH_HOME_STAYS : PATH_HOME
                    )
                  }
                >
                  {logo}
                </Box>
                <Box className={"rewards-account-section-travel-details"}>
                  <Typography variant={"body1"} tabIndex={0}>
                    {constants.VIEWING_TEXT(
                      locationName,
                      isSearchTermLodging,
                      isSearchTermPoint,
                      isMapSearch,
                      staysSearchEnabled
                    )}
                  </Typography>
                  {fromDate && untilDate ? (
                    <Typography variant={"body2"}>
                      {constants.DATES_TEXT(
                        dayjs(fromDate).format("ddd, MMM DD"),
                        dayjs(untilDate).format("ddd, MMM DD")
                      )}
                    </Typography>
                  ) : null}
                </Box>
              </Box>
            }
            right={
              <Box className="desktop-hotel-availability-rewards-account-contents">
                <RewardsAccountSelection
                  className={clsx("b2b", {
                    "hide-balance-border": isTravelWalletCreditsExperiment,
                  })}
                  popoverClassName="b2b"
                />
                {isTravelWalletCreditsExperiment ? (
                  <TravelWalletDrawer />
                ) : null}
              </Box>
            }
          />
        }
        leftClassName={clsx("list-section")}
        left={
          <AvailabilityList
            setOpenPriceDropProtectionBannerModal={
              setOpenPriceDropProtectionBannerModal
            }
          />
        }
        rightClassName={clsx("map-section")}
        right={<AvailabilityMap />}
      />
    );
  };

  const renderMobileView = () => {
    return (
      <Box
        className={clsx("hotel-availability-container", config.TENANT, {
          "mobile-map": mobileMapOpen,
        })}
      >
        <MobileHotelAvailability
          setOpenPriceDropProtectionBannerModal={
            setOpenPriceDropProtectionBannerModal
          }
          onShowMap={setMobileMapOpen}
        />
      </Box>
    );
  };

  const capOneLoadingSecondaryText = showEarnEnhancement
    ? constants.EARN_ENHANCEMENT_SUBTITLE(
        largestValueAccount.earn.hotelsMultiplier,
        staysSearchEnabled ? largestValueAccount.earn.homesMultiplier : "",
        largestValueAccount.productDisplayName,
        largestValueAccount.rewardsBalance.currencyDescription ??
          largestValueAccount.rewardsBalance.currency
      )
    : largestValueAccount?.productDisplayName.toLowerCase().includes("paradise")
    ? constants.PARADISE_SUBTITLE
    : constants.fetchingAvailabilitySecondaryText(staysSearchEnabled);

  const isCorporate = isCorpTenant(config.TENANT);

  const loadingSecondaryMessage = isCorporate
    ? constants.CORP_FETCHING_AVAILABILITY_SECONDARY_TEXT
    : capOneLoadingSecondaryText;

  return (
    <>
      {hotelAvailabilityCallState ===
        HotelAvailabilityCallState.InitialSearchCallInProcess ||
      (matchesMobile &&
        hotelAvailabilityCallState ==
          HotelAvailabilityCallState.InitialMapSearchCallInProcess) ? (
        <B2BLoadingPopup
          open={
            hotelAvailabilityCallState ===
              HotelAvailabilityCallState.InitialSearchCallInProcess ||
            (matchesMobile &&
              hotelAvailabilityCallState ==
                HotelAvailabilityCallState.InitialMapSearchCallInProcess)
          }
          message={constants.fetchingAvailabilityText(staysSearchEnabled)}
          secondaryMessage={loadingSecondaryMessage}
          image={
            isCorporate ? CorpHotelSearchLoadingImage : HotelSearchLoadingImage
          }
          className="hotel-search-loading-popup"
          popupSize={matchesMobile ? "mobile" : "desktop"}
        />
      ) : null}
      <Box
        className={clsx("hotel-availability-root", {
          "full-width": !matchesMobile,
        })}
      >
        {matchesMobile ? renderMobileView() : renderDesktopView()}
      </Box>

      {/* Added this here because Availability List re renders when new results are loaded, so if the modal is open,
      on new results it would close and open again. Adding this here makes it so it doesn't re-render this modal even
      though the results in the background are changing  */}
      <PriceDropProtectionPopup
        amount={userPriceDropAmount}
        openModal={openPriceDropProtectionBannerModal}
        onClose={() => setOpenPriceDropProtectionBannerModal(false)}
      />
      <AvailabilityErrorModal />
    </>
  );
};
