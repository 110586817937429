import React, { useContext, useEffect, useMemo } from "react";
import dayjs from "dayjs";
import { Box, Typography } from "@material-ui/core";
import { RouteComponentProps, Link } from "react-router-dom";
import {
  BOOKING_SUCCESS_TITLE,
  ButtonWrap,
  CurrentPriceVersusCapEnum,
  HotelAddOn,
  HotelAddOnEnum,
  HotelConfirmation,
  UserPreferencesBanner,
  getTotalPriceText,
  useDeviceTypes,
} from "halifax";
import queryStringParser from "query-string";
import clsx from "clsx";
import {
  CallState,
  CorpSessionInfo,
  LodgingCollectionEnum,
  ModalNames,
} from "redmond";
import { useExperimentsById } from "@capone/experiments";

import { BookingSuccessModalConnectorProps } from "./container";
import {
  PATH_CUSTOMER_PROFILE,
  PATH_HOME,
  PATH_TRAVEL_SALE,
  PATH_TRIPS,
} from "../../../../utils/paths";
import * as textConstants from "./textConstants";
import {
  HOTEL_CONFIRMATION_TITLE,
  PORTAL_TITLE,
} from "../../../../lang/textConstants";
import {
  useExperiments,
  getExperimentVariant,
  TREES_MODAL_EXPERIMENT,
  AVAILABLE,
  TRAVEL_SALE,
  TRAVEL_SALE_VARIANTS,
  getExperimentVariantCustomVariants,
  TRAVEL_SALE_ACTIVE,
  CUSTOMER_PROFILE_EXPERIMENT,
  CUSTOMER_PROFILE_VARIANTS,
  CONTROL,
} from "../../../../context/experiments";
import { config } from "../../../../api/config";
import "./styles.scss";
import { ClientContext } from "../../../../App";
import { updateUserSeenModal } from "../../../../../../../cap1-application/b2b-base/src/api/v1/user/updateUserSeenModal";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";

export interface IBookingSuccessModalProps
  extends BookingSuccessModalConnectorProps,
    RouteComponentProps {}

export const BookingSuccessModal = (props: IBookingSuccessModalProps) => {
  const {
    history,
    reservation,
    selectedLodging,
    resetBookState,
    earnString,
    hotelPriceFreezeSummaryDetails,
    confirmationEmail: email = "",
    hasUserSetHotelPreferences,
    userHotelPreferencesCallState,
    selectedTravelersList,
  } = props;
  const { matchesMobile } = useDeviceTypes();

  const { sessionInfo } = useContext(ClientContext);

  const expState = useExperiments();

  const treesModalExperiment = getExperimentVariant(
    expState.experiments,
    TREES_MODAL_EXPERIMENT
  );
  const isTreesModalExperiment = useMemo(
    () => treesModalExperiment === AVAILABLE,
    [treesModalExperiment]
  );

  const travelSalesEventVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    TRAVEL_SALE,
    TRAVEL_SALE_VARIANTS
  );

  const isCustomerProfileExperiment =
    getExperimentVariantCustomVariants(
      expState.experiments,
      CUSTOMER_PROFILE_EXPERIMENT,
      CUSTOMER_PROFILE_VARIANTS
    ) !== CONTROL;

  useEffect(() => {
    document.title = HOTEL_CONFIRMATION_TITLE;

    return () => {
      document.title = PORTAL_TITLE;
    };
  }, []);

  // TODO: since 'reservation' is not preserved on refresh, it would cause undefined error;
  // discuss with PM regarding what the expected behaviour is (e.g.: handle it properly with error modals, or preserve this state).
  if (!reservation) {
    history.push(PATH_HOME);
    return null;
  }

  const checkIn = dayjs(reservation.checkInDate);
  const checkOut = dayjs(reservation.checkOutDate);

  const getAddOns = () => {
    const addOns: HotelAddOn[] = [];

    const hasCfarIncluded = !!reservation.bookedAncillaryIds?.cfarContractId;

    if (hasCfarIncluded) {
      addOns.push({
        title: textConstants.CFAR_TITLE,
        component: (
          <Box className="cfar-subtitle-section">
            <Typography className="cfar-subtitle" variant="inherit">
              {textConstants.CFAR_DESCRIPTION_ONE}
              <ButtonWrap
                className="my-trips-link"
                aria-label={
                  textConstants.CFAR_DESCRIPTION_MY_TRIPS_LINK_ARIA_LABEL
                }
                onClick={() =>
                  history.push({
                    pathname: PATH_TRIPS,
                    search: queryStringParser.stringify({
                      tripId: reservation.reservationId,
                    }),
                  })
                }
              >
                {textConstants.CFAR_DESCRIPTION_MY_TRIPS_LINK}
              </ButtonWrap>
              {textConstants.CFAR_DESCRIPTION_TWO}
            </Typography>
          </Box>
        ),
        type: HotelAddOnEnum.CFAR,
      });
    }
    return addOns;
  };

  const treesModalText = {
    treesModalHeader: textConstants.TREES_MODAL_HEADER,
    treesModalTitle: textConstants.TREES_MODAL_TITLE,
    treesModalSubtitle: textConstants.TREES_MODAL_SUBTITLE,
    treesModalImgLocation: textConstants.TREES_MODAL_IMG_LOCATION,
    treesModalLinkCopy: textConstants.TREES_MODAL_CTA_TEXT,
    treesModalBoldedLinkCopy: textConstants.TREES_BOLDED_MODAL_CTA_TEXT,
  };

  const priceFreezeSavingsString =
    hotelPriceFreezeSummaryDetails?.frozenPriceSummaryNotification &&
    hotelPriceFreezeSummaryDetails?.frozenPriceSummaryNotification?.type !=
      CurrentPriceVersusCapEnum.NewPriceLowerThanFrozenPrice
      ? textConstants.PRICE_FREEZE_SAVINGS(
          getTotalPriceText({
            price:
              hotelPriceFreezeSummaryDetails.frozenPriceSummaryNotification
                .priceFreezeSavingsFiat,
          })
        )
      : undefined;

  const showUserHotelPreferencesBanner =
    isCustomerProfileExperiment &&
    matchesMobile &&
    userHotelPreferencesCallState === CallState.Success &&
    !hasUserSetHotelPreferences;

  const userRoles =
    sessionInfo && "corporateInfo" in sessionInfo
      ? sessionInfo.corporateInfo.role
      : [];
  const isAdmin = userRoles.includes("Admin");

  const isMultiroomAmadeus =
    useExperimentsById("corp-amadeus-multiroom")?.variant === "available";

  const isNfuProductAwarenessEnabled =
    useExperimentsById("corp-nfu-inproduct-awareness")?.variant === "available";

  const isNfuProductAwarenessDebugEnabled =
    useExperimentsById("corp-nfu-inproduct-awareness")?.variant === "debug";

  const hasSeenNfuInfoModal =
    (sessionInfo as CorpSessionInfo).corporateInfo?.hasSeenModalMap?.[
      ModalNames.NFU_IN_PRODUCT_AWARENESS
    ] ?? false;

  const shouldShowNfuProductAwareness =
    (isAdmin && !hasSeenNfuInfoModal && isNfuProductAwarenessEnabled) ||
    isNfuProductAwarenessDebugEnabled;

  return (
    <Box
      className={clsx("hotel-booking-success-container", config.TENANT, {
        mobile: matchesMobile,
      })}
    >
      <HotelConfirmation
        bannerCopy={textConstants.BANNER_COPY}
        title={BOOKING_SUCCESS_TITLE(
          isMultiroomAmadeus,
          selectedTravelersList,
          email
        )}
        subtitle={textConstants.SUBTITLE(reservation.reservationId)}
        nextHeader={textConstants.WHATS_NEXT_HEADER}
        infoCardTitles={{
          flightsTitle: textConstants.FLIGHTS_TITLE,
          flightsDescription: textConstants.FLIGHTS_DESCRIPTION,
          carsTitle: textConstants.CARS_TITLE,
          carsDescription: textConstants.CARS_DESCRIPTION,
          addOnsTitle: textConstants.ADD_ONS_TITLE,
        }}
        lodging={{
          lodging: reservation.lodgingData,
          description: "",
          isPreferred: false,
          isFreeCancel: false,
          isLuxuryCollection:
            selectedLodging?.lodgingCollection ===
              LodgingCollectionEnum.Premier || false,
        }}
        checkIn={checkIn.toDate()}
        checkOut={checkOut.toDate()}
        onFlightsClick={() => {
          history.push("/flights");
          resetBookState();
        }}
        onCarsClick={() => {
          history.push("/cars");
          resetBookState();
        }}
        isMobile={matchesMobile}
        earnString={earnString}
        addOns={getAddOns()}
        displayTrees={isTreesModalExperiment}
        treesModalText={treesModalText}
        priceFreezeSavingsString={priceFreezeSavingsString}
        travelSalesBannerProps={
          travelSalesEventVariant === TRAVEL_SALE_ACTIVE
            ? {
                subtitle: textConstants.TRAVEL_SALES_EVENT_ACTIVE_SUBTITLE,
                ctaText: textConstants.TRAVEL_SALES_EVENT_ACTIVE_CTA,
                ctaOnClick: () => {
                  const path = `${PATH_TRAVEL_SALE}?entryType=hotel_confirmation`;
                  matchesMobile
                    ? history.push(path)
                    : window.open(path, "_blank");
                },
              }
            : undefined
        }
        isProdEnv={window.__mclean_env__.ENV === "production"}
        tenant={config.TENANT}
        shouldShowNfuProductAwareness={shouldShowNfuProductAwareness}
        travelerName={`${selectedTravelersList[0]?.givenName} ${selectedTravelersList[0]?.surname}`}
        handleSeenNfuInfoModal={updateUserSeenModal}
        trackEvent={trackEvent}
      />
      {showUserHotelPreferencesBanner && (
        <UserPreferencesBanner
          type="hotel"
          variant="fixed"
          isMobile
          userPreferencesCallState={CallState.Success}
          userHasSetPreferences={false}
          shouldApplyUserPreferences={false}
          setShouldApplyUserPreferences={() => {}}
          bannerProfileCTA={
            <Link
              to={`${PATH_CUSTOMER_PROFILE}?section=hotel-preferences`}
              className="profile-cta"
            >
              Add travel preferences
            </Link>
          }
          modalProfileCTA={
            <Link
              to={`${PATH_CUSTOMER_PROFILE}?section=hotel-preferences`}
              className="info-modal-primary-cta"
            >
              Add travel preferences
            </Link>
          }
        />
      )}
    </Box>
  );
};
