import {
  useExperimentIsVariant,
  useExperimentsById,
} from "@capone/experiments";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import {
  ApprovalBanner,
  ApprovalReasonStep,
  ApprovalReasonWorkflow,
  B2BSpinner,
  BackButton,
  ContactInfoStep,
  ContactInfoWorkflow,
  Header,
  HotelNeedToKnowPanel,
  IContactInfo,
  Icon,
  IconName,
  LoadingPopup,
  MobileFloatingButton,
  MobilePopoverCard,
  RoomDetails,
  TravelWalletSingleSelectionStep,
  TravelerSelectStep,
  TreesConfirmation,
  InformationalModal as TreesModal,
  emailRegex,
  formatDateTimeWithTimeZone,
  getCancellationPolicyInfo,
  phoneRegex,
  VoidWindowNotice,
  TripPurposePanel,
} from "halifax";
import queryStringParser from "query-string";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { RouteComponentProps } from "react-router";
import {
  ADD_CONTACT_INFO,
  CheckInPolicy,
  ConnectionResultEnum,
  IPerson,
  InvalidEnum,
  ViewedCorpRateDescriptorEntryPoints,
  CancellationReason,
  CancellationPolicyEnum,
} from "redmond";

import { ClientContext } from "../../../../../App";
import { trackEvent } from "../../../../../api/v0/analytics/trackEvent";
import submitForApproval from "../../../../../api/v0/book/book-flow/submitForApproval";
import { fetchCustomerDetails } from "../../../../../api/v0/customer/fetchCustomerDetails";
import {
  AVAILABLE,
  CREDIT_OFFER_STACKING_V1,
  TRAVEL_WALLET_CREDITS_EXPERIMENT,
  TREES_MODAL_EXPERIMENT,
  getExperimentVariant,
  useExperiments,
  HOTELS_CALIFORNIA_BILL_644_EXPERIMENT,
} from "../../../../../context/experiments";
import {
  PATH_BOOK_CONFIRMATION,
  PATH_HOME,
  PATH_SHOP,
} from "../../../../../utils/paths";
import { formatDate } from "../../../../../utils/shared";
import { transformToStringifiedQuery } from "../../../../shop/utils/queryStringHelpers";
import {
  AGENT_FEE,
  HotelBookMobileButton,
  HotelBookSummaryPanel,
  MobileHotelBookPassengerSelection,
  PriceBreakdown,
  PriceBreakdownDropdown,
  TitleSummaryCard,
} from "../../../components";
import { PaymentCard } from "../../PaymentCard";
import { TravelOfferSelection } from "../../TravelOfferSelection";
import {
  CONTACT_INFO_SAVE,
  CONTINUE,
  NEED_TO_KNOW_DETAILS,
  NEED_TO_KNOW_TITLE,
  PRICE_QUOTE_MESSAGE,
  REVIEW_MY_TRIP_TEXT,
  TREES_BOLDED_MODAL_CTA_TEXT,
  TREES_MODAL_CTA_TEXT,
  TREES_MODAL_HEADER,
  TREES_MODAL_SUBTITLE,
  TREES_MODAL_TITLE,
  getCfarSecondaryText,
} from "../../capone/MobileHotelBookWorkflow/textConstants";
import { AdditionalInfoWorkflow } from "../AdditionalInfoWorkflow";
import {
  CONTACT_INFO_SUBTITLE,
  CONTACT_INFO_TITLE_NO_STEP,
} from "../DesktopHotelBookWorkflow/textConstants";
import { CorpMobileHotelBookWorkflowConnectorProps } from "./container";
import "./styles.scss";
import { onOpenCompareBarTooltip } from "../../../../../utils/events";
import { CALIFORNIA_BILL_644_CANCELLATION_TEXT } from "../../capone/DesktopHotelBookWorkflow/textConstants";
import { CfarCancellationPolicy } from "../../../../ancillary/components/addOnComponents/cfar/CfarCancellationPolicy";
import { useShowPolicyBanner } from "@capone/common";

export interface ICorpMobileHotelBookWorkflowProps
  extends RouteComponentProps,
    CorpMobileHotelBookWorkflowConnectorProps {}

enum CorpMobileHotelBookWorkflowStep {
  TravelerInformation,
  AdditionalInformation,
  ContactInformation,
  TravelOfferSelection,
  RewardsAndPayment,
  ApprovalReason,
  Review,
}

export const CorpMobileHotelBookWorkflow = ({
  priceQuote,
  hasNoUserPassengers,
  schedulePriceQuote,
  history,
  chosenProduct,
  selectedLodging,
  roomInfoProduct,
  chosenProductIndex,
  reservation,
  isBookingValid,
  setContactInfo,
  confirmationEmail,
  confirmationPhoneNumber,
  priceDifferenceAcknowledged,
  scheduleBook,
  priceQuoteInProgress,
  hotelQueryParams,
  selectedLodgingIndex,
  priceQuoteErrors,
  confirmationDetailsErrors,
  offers,
  resetPaymentCardSelectedAccounts,
  rewardsPaymentAccountReferenceId,
  setPriceQuote,
  roomsCount,
  checkIn,
  checkOut,
  setSubmitForApproval,
  setSubmitForApprovalFailure,
  opaquePayments,
  priceQuoteRequest,
  updateUserPassenger,
  ancillaries,
  selectedTravelersList,
  setUserSelectedTravelersList,
  credit,
  isTravelWalletPaymentOnly,
  cancellationSummary,
  hasCfarAttached,
  setTripPurpose,
}: ICorpMobileHotelBookWorkflowProps) => {
  const clientContext = useContext(ClientContext);
  const { sessionInfo, isAgentPortal, isAutoApprovalEnabled, policies } =
    clientContext;

  const [contactInfo, setContact] = useState<IContactInfo | null>({
    phoneNumber: confirmationPhoneNumber || "",
    email: confirmationEmail || sessionInfo?.userInfo?.email || "",
  });
  const [requestReason, setRequestReason] = React.useState("");
  const [customerDetailsLoading, setCustomerDetailsLoading] =
    React.useState<boolean>(true);
  const [checkoutStep, setCheckoutStep] =
    useState<CorpMobileHotelBookWorkflowStep>(0);
  const [travelerWorkflowStep, setTravelerWorkflowStep] =
    useState<TravelerSelectStep>(TravelerSelectStep.Main);
  const [contactInfoStep, setContactInfoStep] = useState<ContactInfoStep>(
    ContactInfoStep.Main
  );
  const [travelOfferSelectStep, setTravelOfferSelectStep] =
    useState<TravelWalletSingleSelectionStep>(
      TravelWalletSingleSelectionStep.Main
    );
  const [approvalReasonStep, setApprovalReasonStep] =
    useState<ApprovalReasonStep>(ApprovalReasonStep.Main);
  const [openPaymentCard, setOpenPaymentCard] = useState<boolean>(false);
  const [isAdditionalInfoCardOpen, setIsAdditionalInfoCardOpen] =
    useState<boolean>(false);
  const [treeModalOpen, setTreeModalOpen] = useState(false);
  const [selectedTravelerIndex, setSelectedTravelerIndex] = useState<number>(0);
  const [changedTravelersList, setChangedTravelersList] = useState<IPerson[]>(
    []
  );

  // note: when going back to edit selected travelers in review step, only show additional info modal again for changed travelers
  const additionalInfoTravelersList = changedTravelersList.length
    ? changedTravelersList
    : selectedTravelersList;

  const policyCompliance =
    //TODO(replat): comment back in after corporateTravel is added to price quote
    // priceQuote?.corporateTravel.policyCompliance ??
    roomInfoProduct?.roomInfo.corporateTravel.policyCompliance;

  const isInPolicy = policyCompliance.isInPolicy ?? true;

  const isLoyaltyEligible = Boolean(chosenProduct?.loyaltyProgramCode);

  const isMultiroomAmadeus =
    useExperimentsById("corp-amadeus-multiroom")?.variant === "available";

  const isCaliforniaBill644Experiment = useExperimentIsVariant(
    HOTELS_CALIFORNIA_BILL_644_EXPERIMENT,
    AVAILABLE
  );

  const showFree24HourCancel =
    isCaliforniaBill644Experiment &&
    cancellationSummary?.reasons.includes(CancellationReason.CaliforniaBill644);

  const expState = useExperiments();

  const treesModalExperiment = getExperimentVariant(
    expState.experiments,
    TREES_MODAL_EXPERIMENT
  );
  const isTreesModalExperiment = useMemo(
    () => treesModalExperiment === AVAILABLE,
    [treesModalExperiment]
  );

  const isTravelWalletCreditsExperiment = useExperimentIsVariant(
    TRAVEL_WALLET_CREDITS_EXPERIMENT,
    AVAILABLE
  );

  const isCreditAndOfferStackingExperimentV1 = useExperimentIsVariant(
    CREDIT_OFFER_STACKING_V1,
    AVAILABLE
  );

  const isApprovalsV2Enabled = useExperimentIsVariant(
    "corp-approvals-v2",
    "m2"
  );

  const isTripPurposeXpEnabled = useExperimentIsVariant(
    "corp-trip-purpose",
    AVAILABLE
  );

  const isShoppingCartHotelEnabled = useExperimentIsVariant(
    "c1-shopping-cart-hotels-booking",
    AVAILABLE
  );

  const showPolicyBanner = useShowPolicyBanner(policies, sessionInfo);

  const cancellationPolicyInfo = chosenProduct?.cancellationPolicy
    ? getCancellationPolicyInfo(
        chosenProduct?.cancellationPolicy,
        getCfarSecondaryText
      )
    : null;

  const onBookHotel = () => {
    const queryString = queryStringParser.parse(history.location.search);
    priceDifferenceAcknowledged || !!priceQuote
      ? scheduleBook({
          agentFee: isAgentPortal ? AGENT_FEE : 0,
          isRecommended: queryString.recommended === "true",
          ancillaries,
          loyaltyNumber: undefined,
          approvalRequestReason: requestReason,
          // TODO: add loyalty number back and refactor tracking
          // loyaltyNumber,
        })
      : schedulePriceQuote({
          history,
          agentFee: isAgentPortal ? AGENT_FEE : 0,
          ancillaries,
          // loyaltyNumber,
        });
  };

  const onSubmitForApprovalFailure = () =>
    setSubmitForApprovalFailure({
      Invalid: InvalidEnum.Missing,
      ConnectionResult: ConnectionResultEnum.Invalid,
    });

  const onSubmitForApproval = async () => {
    if (chosenProduct && selectedLodging && roomInfoProduct && priceQuote) {
      const request = {
        approvalReason: requestReason || "",
        cancellationPolicy: chosenProduct.cancellationPolicy,
        checkInDate: formatDate(checkIn) || "",
        checkOutDate: formatDate(checkOut) || "",
        contactEmail: contactInfo?.email || "",
        guests: selectedTravelersList,
        lodgingData: {
          ...selectedLodging?.lodging,
          media: (selectedLodging?.lodging.media || []).slice(0, 1),
          description: selectedLodging?.lodging.description?.replace(
            /(?<=^.{200}).*/s,
            "..."
          ),
        },
        paymentInfo: opaquePayments?.[0]?.value || [],
        pricing: priceQuote.pricing,
        policyCompliance:
          //TODO(replat): comment back in price quote policy compliance after BE work complete
          // priceQuote.corporateTravel.policyCompliance ||
          roomInfoProduct.roomInfo.corporateTravel.policyCompliance,
        roomInfo: {
          ...roomInfoProduct?.roomInfo,
          media: (roomInfoProduct?.roomInfo.media || []).slice(0, 1),
          description: roomInfoProduct?.roomInfo.description?.replace(
            /(?<=^.{200}).*/s,
            "..."
          ),
        },
        // shopRequest: chosenProduct.opaqueQuoteRequest || "",
        state: { ReservationState: "Pending" },
        totalPrice: chosenProduct.tripTotal.fiat,
        trackingProperties: {}, // chosenProduct.trackingPropertiesV2,
        hotelQuoteRequest: priceQuoteRequest,
        requestedAt: formatDateTimeWithTimeZone(new Date()),
        isApprovalRequired: !isAutoApprovalEnabled && !isApprovalsV2Enabled,
      };

      try {
        if (isAutoApprovalEnabled || isApprovalsV2Enabled) {
          const requestId = await submitForApproval(request);
          requestId ? onBookHotel() : onSubmitForApprovalFailure();
        } else {
          setSubmitForApproval(request);
        }
      } catch (error) {
        onSubmitForApprovalFailure();
      }
    } else {
      onSubmitForApprovalFailure();
    }
  };

  const mergedPolicies = React.useMemo(
    () =>
      [
        ...(chosenProduct?.policies ?? []),
        ...(priceQuote?.checkInInstructions.policies ?? []),
      ].reduce((uniquePolicies, currentPolicy) => {
        if (
          !uniquePolicies.some(({ title }) => currentPolicy.title === title)
        ) {
          uniquePolicies.push(currentPolicy);
        }
        return uniquePolicies;
      }, [] as CheckInPolicy[]),
    [chosenProduct?.policies, priceQuote?.checkInInstructions.policies]
  );

  const openHotelBookPassengerSelection = () =>
    setTravelerWorkflowStep(TravelerSelectStep.TravelerSelect);

  const openContactInfoWorkflow = () => {
    setContactInfoStep(ContactInfoStep.ContactInfoForm);
  };

  const openTravelOffersSelection = () => {
    setTravelOfferSelectStep(
      TravelWalletSingleSelectionStep.TravelWalletSelection
    );
  };

  const openPaymentSelection = () => setOpenPaymentCard(true);

  const openApprovalReasonWorkflow = () => {
    setApprovalReasonStep(ApprovalReasonStep.ApprovalReason);
  };

  const openAdditionalInfoCard = () => {
    setIsAdditionalInfoCardOpen(true);
  };

  const incrementCheckoutStep = () => {
    setCheckoutStep((step) => {
      let stepsToSkip = 1; // default to 1 step to skip
      switch (step) {
        case CorpMobileHotelBookWorkflowStep.TravelerInformation:
          // Skip additional information if the hotel is ineligible and multiroom amadeus exp is not enabled
          if (!isLoyaltyEligible && !isMultiroomAmadeus) {
            stepsToSkip += 1;
          }
          break;
        case CorpMobileHotelBookWorkflowStep.ContactInformation:
          // Skip travel wallet for OOP approval requests or if the experiment is not enabled and we were not eligible for loyalty
          if (
            !isTravelWalletCreditsExperiment ||
            (!isInPolicy && !isAutoApprovalEnabled) ||
            isCreditAndOfferStackingExperimentV1 ||
            (!credit && !offers?.length)
          ) {
            stepsToSkip += 1;
          }
          break;
        case CorpMobileHotelBookWorkflowStep.TravelOfferSelection:
          if (isTravelWalletPaymentOnly) {
            stepsToSkip += 1;
          }
          break;
        case CorpMobileHotelBookWorkflowStep.RewardsAndPayment:
          // Skip out of policy step if the hotel is in policy
          if (isInPolicy) {
            stepsToSkip += 1;
          }
          break;
        case CorpMobileHotelBookWorkflowStep.Review:
          // There is nowhere to go after the review step
          stepsToSkip = 0;
      }
      return step + stepsToSkip;
    });
  };

  const handleGoBack = () => {
    switch (checkoutStep) {
      case CorpMobileHotelBookWorkflowStep.TravelerInformation:
        if (selectedLodging) {
          const params = transformToStringifiedQuery({
            lodgingId: selectedLodging.lodging.id,
            ...hotelQueryParams,
            selectedLodgingIndex,
            roomsCount,
          });

          history.push(`${PATH_SHOP}${params}`);
        } else {
          history.push(PATH_HOME);
        }
        break;

      case CorpMobileHotelBookWorkflowStep.ContactInformation:
        if (!isLoyaltyEligible && !isMultiroomAmadeus) {
          // Skip hotel loyalty if the hotel is ineligible and multiroom amadeus exp is not enabled
          setCheckoutStep((step) => step - 2);
        } else {
          setCheckoutStep((step) => step - 1);
        }
        break;
      case CorpMobileHotelBookWorkflowStep.RewardsAndPayment:
        let stepsToSkip = 1; // default to 1 step to skip

        // Skip travel wallet if the experiment is not enabled or there are no offers
        if (
          !isTravelWalletCreditsExperiment ||
          (!isInPolicy && !isAutoApprovalEnabled) ||
          isCreditAndOfferStackingExperimentV1 ||
          (!credit && !offers?.length)
        ) {
          stepsToSkip += 1;
        }

        setCheckoutStep((step) => step - stepsToSkip);

        break;
      case CorpMobileHotelBookWorkflowStep.Review:
        if (isInPolicy) {
          setCheckoutStep((step) => step - 2);
        } else {
          setCheckoutStep((step) => step - 1);
        }
        break;
      default:
        setCheckoutStep((step) => step - 1);
        break;
    }
  };

  const closeAllPopovers = () => {
    setTravelerWorkflowStep(TravelerSelectStep.Main);
    setContactInfoStep(ContactInfoStep.Main);
    setTravelOfferSelectStep(TravelWalletSingleSelectionStep.Main);
    setApprovalReasonStep(ApprovalReasonStep.Main);
    setOpenPaymentCard(false);
    setIsAdditionalInfoCardOpen(false);
  };

  const startSchedulePriceQuoteAndResetPayment = () => {
    schedulePriceQuote({
      history,
      agentFee: isAgentPortal ? AGENT_FEE : 0,
      pollQuoteOnly: true,
      ancillaries,
      // TODO: add loyalty number back and refactor tracking
      // loyaltyNumber,
    });
    resetPaymentCardSelectedAccounts();
  };

  useEffect(() => {
    if (priceQuoteErrors.length > 0 || confirmationDetailsErrors.length > 0) {
      setCheckoutStep(CorpMobileHotelBookWorkflowStep.TravelerInformation);
    }
  }, [priceQuoteErrors.length, confirmationDetailsErrors.length]);

  useEffect(() => {
    switch (checkoutStep) {
      case CorpMobileHotelBookWorkflowStep.TravelerInformation:
        closeAllPopovers();
        openHotelBookPassengerSelection();
        break;
      case CorpMobileHotelBookWorkflowStep.AdditionalInformation:
        closeAllPopovers();
        openAdditionalInfoCard();
        break;
      case CorpMobileHotelBookWorkflowStep.ContactInformation:
        closeAllPopovers();
        openContactInfoWorkflow();
        break;
      case CorpMobileHotelBookWorkflowStep.TravelOfferSelection:
        closeAllPopovers();
        openTravelOffersSelection();
        break;
      case CorpMobileHotelBookWorkflowStep.RewardsAndPayment:
        closeAllPopovers();
        openPaymentSelection();
        break;
      case CorpMobileHotelBookWorkflowStep.ApprovalReason:
        closeAllPopovers();
        openApprovalReasonWorkflow();
        break;
      default:
        break;
    }
  }, [checkoutStep]);

  // note: it handles the PassengerSelection step logic separately from the previous useEffect;
  // when travelerWorkflowStep is being changed too rapidly (e.g.: changing from Main -> TravelerSelect -> TravelerInfoForm because of hasUserPassengers value)
  // it seems to cause some unexpected behaviours on TravelerSelectWorkflow, which in turn causes the Review screen to be unscrollable
  // https://hopper-jira.atlassian.net/browse/BP-1090
  useEffect(() => {
    if (
      checkoutStep === CorpMobileHotelBookWorkflowStep.TravelerInformation &&
      hasNoUserPassengers
    ) {
      setTravelerWorkflowStep(TravelerSelectStep.TravelerInfoForm);
    }
  }, [checkoutStep, hasNoUserPassengers]);

  useEffect(() => {
    if (reservation) {
      history.push(PATH_BOOK_CONFIRMATION);
    }
  }, [reservation]);

  React.useEffect(() => {
    const getCustomerDetails = async () => {
      try {
        const details = await fetchCustomerDetails();
        setContact({
          email: "",
          ...contactInfo,
          phoneNumber: details?.phoneNumber || "",
        });
        setContactInfo(contactInfo?.email || "", details?.phoneNumber || "");
      } finally {
        setCustomerDetailsLoading(false);
      }
    };

    getCustomerDetails();
  }, []);

  useEffect(() => {
    if (
      checkoutStep === CorpMobileHotelBookWorkflowStep.Review &&
      !rewardsPaymentAccountReferenceId
    ) {
      if (isInPolicy) {
        handleGoBack();
      } else {
        setCheckoutStep(CorpMobileHotelBookWorkflowStep.RewardsAndPayment);
      }
    }
  }, [checkoutStep, rewardsPaymentAccountReferenceId]);

  useEffect(() => {
    if (priceQuoteInProgress && !!priceQuote) {
      setPriceQuote({
        priceQuote: null,
        pricingWithAncillaries: null,
        hotelAncillaryQuotes: null,
        type: "hotel-checkout",
      });
    }
  }, [priceQuoteInProgress]);

  return (
    <>
      <Box
        className={clsx(
          "corp-mobile-hotel-book-workflow-root",
          "display-room-details-modal",
          {
            "mobile-review-hotel-book":
              checkoutStep === CorpMobileHotelBookWorkflowStep.Review,
          }
        )}
      >
        <Header
          className={clsx("mobile-hotel-book-header", "checkout")}
          left={
            <BackButton
              className="mobile-hotel-book-header-go-back"
              onClick={handleGoBack}
            />
          }
          right={<PriceBreakdownDropdown />}
          isMobile={true}
          fullWidth={true}
        />
        <TitleSummaryCard isMobile />
        {showFree24HourCancel && <VoidWindowNotice />}
        {!isInPolicy && !isApprovalsV2Enabled && (
          <Box p="0px 16px 50px">
            <ApprovalBanner
              isApprovalRequired={
                isApprovalsV2Enabled
                  ? policies?.settings && policies.settings.isApprovalRequired
                  : !isAutoApprovalEnabled
              }
            />
          </Box>
        )}
        <HotelBookSummaryPanel
          isMobile
          className="b2b"
          chosenProduct={chosenProduct}
          onViewCorpCompareBar={onOpenCompareBarTooltip(
            ViewedCorpRateDescriptorEntryPoints.HOTELS_CHECKOUT
          )}
        />
        {!!roomInfoProduct && chosenProductIndex !== null && (
          <RoomDetails
            roomInfoProduct={roomInfoProduct}
            productIndex={chosenProductIndex}
            lodging={selectedLodging}
            isMobile
            hideAmenities
            roomsCount={roomsCount}
            hideRoomBedDescription
            hideRoomCapacity
            showAmenitiesModal
            policyCompliance={showPolicyBanner ? policyCompliance : undefined}
          />
        )}
        <MobileHotelBookPassengerSelection
          progress={travelerWorkflowStep}
          setProgress={setTravelerWorkflowStep}
          onGoBack={(travelersChanged) => {
            switch (checkoutStep) {
              case CorpMobileHotelBookWorkflowStep.TravelerInformation:
                handleGoBack();
                break;
              case CorpMobileHotelBookWorkflowStep.Review:
                // note: when the user changes passengers on the final step and then clicks the go-back button,
                // handle it as if the user clicked continue;
                if (travelersChanged.length) {
                  startSchedulePriceQuoteAndResetPayment();
                }
                break;
              default:
                break;
            }
          }}
          onContinue={(travelersChanged) => {
            switch (checkoutStep) {
              case CorpMobileHotelBookWorkflowStep.Review:
                if (travelersChanged.length) {
                  setChangedTravelersList(
                    selectedTravelersList.filter((traveler) =>
                      travelersChanged.includes(traveler.id)
                    )
                  );
                  setIsAdditionalInfoCardOpen(true);
                }
                break;
              default:
                incrementCheckoutStep();
                break;
            }
          }}
          selectionScreenHeaderElement={<PriceBreakdownDropdown />}
          className="b2b capone-corporate"
          onReviewStep={checkoutStep === CorpMobileHotelBookWorkflowStep.Review}
          showHotelLoyaltySection={isLoyaltyEligible}
          showVoidWindowNotice={showFree24HourCancel ?? false}
        />

        <AdditionalInfoWorkflow
          open={isAdditionalInfoCardOpen}
          isLoyaltyEligible={isLoyaltyEligible}
          onClickContinue={(traveler: IPerson) => {
            updateUserPassenger({ person: traveler });
            if (
              selectedTravelerIndex <
              additionalInfoTravelersList.length - 1
            ) {
              setSelectedTravelerIndex(selectedTravelerIndex + 1);
            } else {
              // note: userSelectedTravelersList in state is needed in booking confirmation modal to show emails
              setUserSelectedTravelersList(selectedTravelersList);
              setSelectedTravelerIndex(0);
              if (checkoutStep === CorpMobileHotelBookWorkflowStep.Review) {
                startSchedulePriceQuoteAndResetPayment();
                setIsAdditionalInfoCardOpen(false);
                openPaymentSelection();
              } else {
                incrementCheckoutStep();
              }
            }
          }}
          onGoBack={() => {
            if (selectedTravelerIndex > 0) {
              setSelectedTravelerIndex(selectedTravelerIndex - 1);
            } else {
              handleGoBack();
            }
          }}
          mobileHeaderElement={<PriceBreakdownDropdown />}
          className="capone-corporate"
          traveler={additionalInfoTravelersList[selectedTravelerIndex]}
        />
        <ContactInfoWorkflow
          titles={{
            contactInfoTitle: CONTACT_INFO_TITLE_NO_STEP(isMultiroomAmadeus),
            contactInfoSubtitle: CONTACT_INFO_SUBTITLE,
            buttonTitle:
              checkoutStep === CorpMobileHotelBookWorkflowStep.Review
                ? CONTACT_INFO_SAVE
                : CONTINUE,
          }}
          progressStep={contactInfoStep}
          setProgressStep={setContactInfoStep}
          isMobile={true}
          contactInfo={contactInfo}
          onGoBack={() => {
            if (
              checkoutStep ===
              CorpMobileHotelBookWorkflowStep.ContactInformation
            ) {
              handleGoBack();
            }
          }}
          onContactInfoChange={(newContactInfo) => {
            setContact(newContactInfo);
            setContactInfo(newContactInfo.email, newContactInfo.phoneNumber);
            if (
              newContactInfo.email &&
              newContactInfo.phoneNumber &&
              emailRegex.test(newContactInfo.email) &&
              phoneRegex.test(newContactInfo.phoneNumber)
            ) {
              trackEvent({
                eventName: ADD_CONTACT_INFO,
                properties: {},
              });
            }
            incrementCheckoutStep();
          }}
          mobileHeaderElement={<PriceBreakdownDropdown />}
          className="b2b capone-corporate"
          onContinueClick={(newContactInfo) => {
            switch (checkoutStep) {
              case CorpMobileHotelBookWorkflowStep.Review:
                if (
                  newContactInfo.email !== contactInfo?.email ||
                  newContactInfo.phoneNumber !== contactInfo?.phoneNumber
                ) {
                  startSchedulePriceQuoteAndResetPayment();
                  openPaymentSelection();
                }
                break;
              default:
                schedulePriceQuote({
                  history,
                  agentFee: isAgentPortal ? AGENT_FEE : 0,
                  pollQuoteOnly: true,
                  ancillaries,
                  // TODO: add loyalty number back and refactor tracking
                  // loyaltyNumber,
                });
                break;
            }
          }}
          loading={customerDetailsLoading}
          bottomContent={
            showFree24HourCancel ? <VoidWindowNotice /> : undefined
          }
        />
        {isTravelWalletCreditsExperiment &&
          !isCreditAndOfferStackingExperimentV1 && (
            <TravelOfferSelection
              progressStep={travelOfferSelectStep}
              mobileHeaderElement={<PriceBreakdownDropdown />}
              isMobile
              onGoBack={() => {
                if (
                  checkoutStep ===
                  CorpMobileHotelBookWorkflowStep.TravelOfferSelection
                ) {
                  handleGoBack();
                }
              }}
              onContinueClick={() => {
                incrementCheckoutStep();
                isTravelWalletPaymentOnly &&
                  setTravelOfferSelectStep(
                    TravelWalletSingleSelectionStep.Main
                  );
              }}
            />
          )}
        <PriceBreakdown />
        {hasCfarAttached ? (
          <CfarCancellationPolicy />
        ) : (
          cancellationPolicyInfo && (
            <Box className={"cancellation-details"}>
              <Typography variant="h4">
                {cancellationPolicyInfo.primaryText}
              </Typography>
              <Typography variant="body2">
                {showFree24HourCancel &&
                chosenProduct?.cancellationPolicy.CancellationPolicy ===
                  CancellationPolicyEnum.NonRefundable
                  ? CALIFORNIA_BILL_644_CANCELLATION_TEXT
                  : cancellationPolicyInfo.secondaryText}
              </Typography>
            </Box>
          )
        )}
        <HotelNeedToKnowPanel
          title={NEED_TO_KNOW_TITLE}
          subtitle={NEED_TO_KNOW_DETAILS}
          policies={mergedPolicies}
          fetchingPriceQuote={priceQuoteInProgress}
          className={clsx({
            "less-padding":
              isTreesModalExperiment || !isInPolicy || isTripPurposeXpEnabled,
          })}
        />
        {!isInPolicy && (
          <>
            <ApprovalReasonWorkflow
              buttonText="Review my trip"
              progressStep={approvalReasonStep}
              setProgressStep={setApprovalReasonStep}
              mobileHeaderElement={<PriceBreakdownDropdown />}
              onGoBack={handleGoBack}
              className="b2b approval-reason-workflow"
              corporateTravel={roomInfoProduct?.roomInfo.corporateTravel}
              onContinueClick={(reason) => {
                incrementCheckoutStep();
                setRequestReason(reason);
              }}
              onError={() =>
                trackEvent({
                  eventName: "approval_reason_char_limit_error",
                  properties: {
                    funnel: "hotels",
                  },
                })
              }
            />
            <Box p="0px 16px 50px">
              <ApprovalBanner
                isApprovalRequired={!isAutoApprovalEnabled}
                isApprovalsV2Enabled={isApprovalsV2Enabled}
              />
            </Box>
          </>
        )}
        {isTreesModalExperiment && (
          <TreesModal
            image={TreesConfirmation}
            header={TREES_MODAL_HEADER}
            title={TREES_MODAL_TITLE}
            onClose={() => setTreeModalOpen(false)}
            subtitle={TREES_MODAL_SUBTITLE}
            icon={<Icon className="trees-icon" name={IconName.TreesIcon} />}
            openModal={treeModalOpen}
            setOpenModal={setTreeModalOpen}
            modalLinkCopy={TREES_MODAL_CTA_TEXT}
            modalButtonCopyStartIcon={
              <Icon className="trees-icon" name={IconName.TreesIcon} />
            }
            isMobile
            boldedModalLinkCopy={TREES_BOLDED_MODAL_CTA_TEXT}
          />
        )}

        {isTripPurposeXpEnabled && (
          <TripPurposePanel setTripPurpose={setTripPurpose} isMobile />
        )}

        <HotelBookMobileButton
          hasContactInfo={!!contactInfo}
          openContactInfo={() => {
            setContactInfoStep(ContactInfoStep.ContactInfoForm);
          }}
          isBookingValid={isBookingValid}
          onConfirmAndBook={
            // TODO: CA-2198 -
            // Once approval v2 is turned on and trip request is gone, only call onBookHotel if isApprovalsV2Enabled is true
            isInPolicy || (isApprovalsV2Enabled && isShoppingCartHotelEnabled)
              ? onBookHotel
              : onSubmitForApproval
          }
          showPaymentStep={true}
          onApplyRewards={() => {}}
          isInPolicy={isInPolicy}
        />
      </Box>
      <MobilePaymentCardPopover
        openPaymentCard={openPaymentCard}
        disabled={!isBookingValid}
        onClose={() => setOpenPaymentCard(false)}
        onConfirm={() => {
          incrementCheckoutStep();
          setOpenPaymentCard(false);
        }}
        onGoBack={() => {
          if (
            checkoutStep === CorpMobileHotelBookWorkflowStep.RewardsAndPayment
          ) {
            handleGoBack();
          }
        }}
        mobileHeaderElement={<PriceBreakdownDropdown />}
      />
      <LoadingPopup
        classes={["mobile-price-quote-loading-popup"]}
        open={priceQuoteInProgress}
        message={PRICE_QUOTE_MESSAGE}
        indicator={B2BSpinner}
        indicatorSize="small"
        verticalAlignment="center"
        fullScreen={true}
      />
    </>
  );
};

interface IMobilePaymentCardPopoverProps {
  openPaymentCard: boolean;
  disabled?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onGoBack: () => void;
  mobileHeaderElement?: JSX.Element;
  isInPolicy?: boolean;
}

const MobilePaymentCardPopover = (props: IMobilePaymentCardPopoverProps) => {
  const {
    openPaymentCard,
    disabled = true,
    onClose,
    onConfirm,
    onGoBack,
    mobileHeaderElement,
    isInPolicy,
  } = props;

  return (
    <MobilePopoverCard
      open={openPaymentCard}
      className={clsx("hotel-payment-card-popup", "b2b", "capone-corporate")}
      fullScreen={true}
      onClose={onClose}
      topLeftButton={
        <BackButton
          className="hotel-payment-card-popup-back-button"
          onClick={() => {
            onGoBack();
            onClose();
          }}
        />
      }
      headerElement={mobileHeaderElement}
    >
      <PaymentCard />
      {!disabled && (
        <MobileFloatingButton
          className="hotel-book-review-trip-button"
          onClick={onConfirm}
          wrapperClassName="b2b"
        >
          {isInPolicy ? REVIEW_MY_TRIP_TEXT : CONTINUE}
        </MobileFloatingButton>
      )}
    </MobilePopoverCard>
  );
};
